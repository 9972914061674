<template>
    <div class="update">
        <div class="update-wrapper">
            <h1>{{ $t('account_details') }}</h1>

            <div class="details-wrapper">
                <form @submit.prevent="update()" class="update-form">
                    <div class="input-wrapper">
                        <p class="label">{{ $t('profile_image') }}</p>

                        <div class="preview-wrapper" v-if="url">
                            <img :src="url" class="preview"/>
                            <div class="close" @click="url = null"></div>
                        </div>

                        <div class="new-profile" v-if="url">
                            <p class="new">{{ $t('upload_image') }}</p>
                            <input type="file" @change="onFileChange" class="file">
                        </div>

                        <div class="drop" v-else>
                            <input type="file" @change="onFileChange" class="file">
                            <p class="upload">{{ $t('upload_image') }}</p>
                            <p class="drag">{{ $t('drag_and_drop') }}</p>
                        </div>
                    </div>

                    <div class="input-wrapper">
                        <p class="label">{{ $t('name') }}</p>
                        <input v-model="name" type="text" :placeholder="$t('first_name') + ' ' + $t('last_name')" required>
                    </div>

                    <div class="input-wrapper">
                        <p class="label">{{ $t('email_address') }}</p>
                        <input v-model="email" type="text" :placeholder="$t('email_address')" disabled>
                    </div>

                    <div class="input-wrapper">
                        <p class="label">{{ $t('school_org_name') }}</p>
                        <input v-model="org" type="text" :placeholder="$t('school_org_name')">
                    </div>

                    <div class="input-wrapper">
                        <p class="label">{{ $t('description') }}</p>
                        <textarea v-model="desc" cols="30" rows="3" :placeholder="$t('write_something')"></textarea>
                    </div>

                    <button class="cancel" :disabled="loading" @click="cancel()">{{ $t('cancel') }}</button>
                    <button class="submit" type="submit" :disabled="loading">{{ $t('save_changes') }}</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields'

export default {
    data () {
        return {
            url: null,
            file: null,

            name: '',
            email: '',
            org: '',
            desc: ''
        }
    },

    created () {
        let { name, email, organization, description } = this.user
        this.name = name 
        this.email = email
        this.org = organization || ''
        this.desc = description || ''
        this.url = this.user.profile_picture ? this.profilePicture : ''
    },

    computed: {
        ...mapFields(['user', 'loading']),

        profilePicture () {
            return process.env.VUE_APP_API_URL + '/storage/' + this.user.profile_picture
        }
    },

    methods: {
        onFileChange (e) {
            const file = e.target.files[0]
            this.file = file
            this.url = URL.createObjectURL(file)
        },

        async update () {
            let form = new FormData

            form.append('name', this.name)
            form.append('organization', this.org)
            form.append('description', this.desc)
            if (this.file) form.append('profile_picture', this.file)

            await this.$store.dispatch('updateAccount', form)
        },

        cancel () {
            this.$router.push('/account')
        }
    }
}
</script>

<style lang="scss" scoped>
.update {
    background: #F3F3F3;
    color: #221E1F;

    .update-wrapper {
        max-width: 1200px;
        padding: 30px 20px;
        margin: auto;
        
        h1 {
            font-family: 'Crimson Pro', sans-serif;
            font-size: 34px;
            font-weight: 900;
            padding-bottom: 24px;
            border-bottom: 1px solid #B8B8B8;
        }

        .details-wrapper {
            padding: 20px 0;
            display: flex;

            .input-wrapper {
                margin-bottom: 15px;

                .label {
                    font-size: 14px;
                    margin-bottom: 10px;
                }

                input, textarea {
                    font-family: 'Crimson Pro', sans-serif;
                    width: 320px;
                    font-size: 12px;
                    padding: 10px;
                    border: 1px solid #DAD2D2;
                    border-radius: 4px;
                    resize: none;

                    @include desktop {
                        width: 400px;
                    }

                    &:focus {
                        outline: none;
                    }
                }

                .preview-wrapper {
                    position: relative;
                    width: 120px;
                    height: 120px;
                    margin-bottom: 8px;

                    .preview {
                        width: 120px;
                        height: 120px;
                    }

                    .close {
                        position: absolute;
                        right: 5px;
                        top: 8px;
                        width: 16px;
                        height: 15px;
                        opacity: 1;
                        background: rgba(0, 0, 0, 0.31);
                        cursor: pointer;

                        &::before, &::after {
                            position: absolute;
                            left: 7px;
                            content: ' ';
                            height: 15px;
                            width: 2px;
                            background-color: #ffffff;
                        }

                        &::before {
                            transform: rotate(45deg);
                        }

                        &::after {
                            transform: rotate(-45deg);
                        }
                    }
                }

                .new-profile {
                    width: 120px;
                    text-align: center;
                    position: relative;
                    cursor: pointer;

                    .new {
                        font-family: 'Crimson Pro', sans-serif;
                        font-size: 12px;
                        color: #00AEEF;
                        display: block;
                        cursor: pointer;
                    }

                    .file {
                        height: 100%;
                        width: 120px;
                        opacity: 0;
                        border: none;
                        z-index: 1;
                        position: absolute;
                        left: 0;
                        top: 0;
                        padding: 0;
                        cursor: pointer;
                    }
                }

                .drop { 
                    width: 120px;
                    height: 120px;
                    background: #DFDFDF;
                    border-radius: 0px;
                    border: none;
                    text-align: center;
                    font-size: 12px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .file {
                        width: 120px;
                        height: 120px;
                        background: #DFDFDF;
                        border-radius: 0px;
                        border: none;
                        opacity: 0;
                        z-index: 1;
                        position: absolute;
                        left: 0;
                        cursor: pointer;
                    }

                    p {
                        font-family: 'Crimson Pro', sans-serif;

                        &.upload {
                            color: #00AEEF;
                            margin-bottom: 7px;
                        }

                        &.drag {
                            width: 63px;
                            margin: 0 auto;
                        }
                    }

                }
            }

            button {
                width: 125px;
                font-weight: 600;
                font-size: 15px;
                padding: 12px 0;

                &:disabled {
                    opacity: 0.5;
                }

                &.cancel {
                    border: 1px solid #FF6868;
                    margin-right: 16px;
                    background: transparent;
                    color: #FF6868;
                }

                &.submit {
                    background: #00AEEF;
                    color: #ffffff;
                }
            }
        }
    }
}
</style>